import CheckBox from "@mui/material/Checkbox";
import StatusChip from "../../../common/StatusChip";
import colors from "../../../common/colors";
import { statusLabels } from "./statusLabels";

export const columns = (selected, handleSelectClick) => [
  {
    field: "select",
    headerName: " ",
    width: 60,
    renderCell: (params) => {
      const isSelected = selected.includes(params.row.id);
      return (
        <CheckBox
          checked={isSelected}
          sx={{
            "&.Mui-checked": {
              color: colors.lavender,
            },
          }}
          size="small"
          onChange={(event) => handleSelectClick(event, params.row.id)}
        />
      );
    },
  },
  {
    field: "id",
    headerName: "Id",
    width: 100,
    filterable: false,
  },
  {
    field: "lastName",
    headerName: "Nachname",
    width: 190,
  },
  {
    field: "firstName",
    headerName: "Vorname",
    width: 190,
  },
  {
    field: "mail",
    headerName: "E-Mail",
    width: 290,
  },
  {
    field: "teams",
    headerName: "Teams",
    width: 230,
  },
  {
    field: "role",
    headerName: "Rolle",
    width: 130,
  },
  {
    field: "status",
    headerName: "Status",
    sort: "desc",
    width: 120,
    valueGetter: (params) => statusLabels[params.value] || "Unbekannt",
    renderCell: (params) => {
      return <StatusChip status={params.row.status} />;
    },
  },
];
