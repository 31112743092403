import { Tab } from "@mui/material";
import { Tabs } from "@mui/material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

export const StyledTab = styled(Tab)`
  color: grey;
  font: inherit;
  font-weight: 500;
  font-size: 20px;
  &.Mui-selected {
    color: ${colors.lavender};
  }
`;

export const ChartNavigationBarContainer = styled("div")`
  position: sticky;
  z-index: 1;
  top: 65px;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${colors.lavender};
  }
  .MuiTab-root {
    text-transform: none;
  }
`;
