import { ChartNavigationBarContainer, StyledTab, StyledTabs } from "./styles";

import React from "react";
import { diversityContent } from "../../../../diversityContent";

interface NavigationBarProps {
  refs: Record<string, React.RefObject<HTMLDivElement>>;
  dimensionKeys: string[];
  currentKey: string;
}

const ChartNavigationBar: React.FC<NavigationBarProps> = (
  props: NavigationBarProps
) => {
  const scrollToRef = (key: string) => {
    const element = props.refs[key].current;
    if (element) {
      const offset = window.innerHeight * 0.24;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <ChartNavigationBarContainer>
      <StyledTabs
        value={props.currentKey}
        onChange={(event, value) => scrollToRef(value)}
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {props.dimensionKeys.map((key) => (
          <StyledTab
            key={key}
            label={diversityContent[key as keyof typeof diversityContent]}
            value={key}
          />
        ))}
      </StyledTabs>
    </ChartNavigationBarContainer>
  );
};

export default ChartNavigationBar;
