export const MenuItems = {
  Contrast: {
    title: "Contrast",
    path: "contrast",
  },
  Report: {
    title: "Report",
    path: "report",
  },
  Areas: {
    title: "Areas",
    path: "areas",
  },
  Dialog: {
    title: "Dialog",
    path: "dialog",
  },
  Pulse: {
    title: "Pulse",
    path: "pulse",
  },
  Diversity: {
    title: "Diversity",
    path: "diversity",
  },
};
