const ADMINROUTES = [
  "/dialog",
  "/report",
  "/contrast",
  "/areas",
  "/settings/teams",
  "/settings/team",
  "/settings/schedule",
  "/settings/users",
  "/diversity",
];
const SURVEEROUTES = ["/dialog", "/pulse", "/settings/profile"];

export const routes = {
  admin: ADMINROUTES,
  "super admin": ADMINROUTES,
  survee: SURVEEROUTES,
};
