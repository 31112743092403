import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Container, Header } from "./styles";

import LegendFields from "../../../DiversityOverview/ChartContainer/DiversityChart/LegendFields";
import React from "react";
import colors from "../../../../common/colors";

const COLORS = [
  colors.lavender,
  colors.coral,
  colors.darkGrey,
  colors.midnight,
  colors.lime,
  colors.natural,
];

//const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = (data) => {
//   const { cx, cy, midAngle, innerRadius, outerRadius, percent, name } = data;
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {`${name} ${(percent * 100).toFixed(0)} %`}
//     </text>
//   );
// };

const OverviewChart = ({
  name,
  data,
  alignLegend = "center",
  verticalAlign = "bottom",
  width = "200px",
}) => {
  return (
    <Container width={width}>
      <Header>{name}</Header>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="60%"
            cy="50%"
            outerRadius={55}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign={verticalAlign}
            align={alignLegend}
            height={75}
            layout="vertical"
            content={LegendFields}
          />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default OverviewChart;
