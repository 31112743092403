export const buildSurveeOverview = (employees) => {
  const data = [
    {
      name: "active",
      value: 0,
    },
    { name: "invited", value: 0 },
    { name: "created", value: 0 },
    { name: "deactivated", value: 0 },
  ];

  for (const employee of employees) {
    if (employee.status === "active") {
      data[0].value++;
    } else if (employee.status === "invited") {
      data[1].value++;
    } else if (employee.status === "created") {
      data[2].value++;
    } else if (employee.status === "deactivated") {
      data[3].value++;
    }
  }

  const activeEmployeeCount =
    data[0].value + data[1].value + data[2].value + data[3].value;

  const surveeOverview = {
    id: "1",
    name: `${activeEmployeeCount} Pulse-Check-Teilnehmende`,
  };

  surveeOverview.data = data;
  return surveeOverview;
};
