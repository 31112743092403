import styled from "@emotion/styled";

export const GrayScaleLogo = styled.img`
  filter: grayscale(100%);
  width: 15%;
  height: 15%;
`;

export const InactiveChartContainer = styled("tr")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
