import {
  ContentContainer,
  MobileOuterContainer,
  TextContainer,
} from "./styles";

import { Button } from "@mui/material";
import Heading1 from "../../../common/Heading1";
import Heading3 from "../../../common/Heading3";
import SurveeOnboardingContainer from "../SurveeOnboardingContainer";
import Text from "../../../common/Text";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../../hooks/usePortalSize";

const ThankYouStep = ({ props }) => {
  const { handleNextStep, employee } = props;
  const size = usePortalSize();
  const { updateEmployee } = useLightsApiPrivate();

  const saveAndFinish = async () => {
    const emp = buildEmployee(employee);
    await updateEmployee(employee._id, { ...emp, onboardingStep: "done" });
    handleNextStep();
  };

  const isSurvee = employee?.role === "survee";
  const buttonLabel = isSurvee ? "Zur Übersicht" : "Zum Dashboard";
  const isMobile = size === "s";

  const surveeInfoText =
    'Du kannst Deine Daten jederzeit ändern, indem Du auf den Menüpunkt "Profil" klickst.';
  const adminInfoText =
    'Du kannst Deine Daten jederzeit ändern, indem Du in die Survee-Ansicht wechselst und dort auf den Menüpunkt "Profil" klickst.';

  const infoText = isSurvee ? surveeInfoText : adminInfoText;

  return (
    <SurveeOnboardingContainer className={size}>
      {isMobile && <MobileOuterContainer />}
      <ContentContainer className={size}>
        <TextContainer>
          <Heading1>Vielen Dank!</Heading1>
          <Text className={size}>{infoText}</Text>
          <Heading3>Viel Spaß bei den Pulse-Checks.</Heading3>
        </TextContainer>
        {!isMobile && (
          <Button
            variant="confirm"
            onClick={() => {
              saveAndFinish();
            }}
          >
            {buttonLabel}
          </Button>
        )}
      </ContentContainer>
      {isMobile && (
        <MobileOuterContainer>
          <Button
            variant="confirm"
            onClick={() => {
              saveAndFinish();
            }}
          >
            {buttonLabel}
          </Button>
        </MobileOuterContainer>
      )}
    </SurveeOnboardingContainer>
  );
};

export default ThankYouStep;
