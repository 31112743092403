const surveeAreaItems = [
  {
    title: "Pulse",
    navigateTo: "pulse",
    path: "/pulse",
  },
  {
    title: "Dialog",
    navigateTo: "dialog",
    path: "/dialog",
  },
];

const adminAreaItems = [
  {
    title: "Report",
    navigateTo: "report",
    path: "/report",
  },
  {
    title: "Contrast",
    navigateTo: "contrast",
    path: "/contrast",
  },
  {
    title: "Areas",
    navigateTo: "areas",
    path: "/areas",
  },
  {
    title: "Dialog",
    navigateTo: "dialog",
    path: "/dialog",
  },
  {
    title: "Diversity",
    navigateTo: "diversity",
    path: "/diversity",
  },
];

const areaItems = {
  survee: surveeAreaItems,
  admin: adminAreaItems,
  "super admin": adminAreaItems,
};

export default areaItems;
