import { getOptionById } from "../../../../settings/profile/diversityOptions";

export const CustomXAxisTick = ({ x, y, payload }: any) => {
  const getLabelById = (id: string) => {
    switch (id) {
      case "niedrig":
      case "mittel":
      case "hoch":
      case "gehoben":
        return id;
      default:
        return getOptionById(id)?.label ?? id;
    }
  };

  const option = getLabelById(payload.value);
  const payloadArray = option.split(/[\s_]+/);

  return (
    <svg>
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          fontSize={12}
          transform="rotate(-45)"
        >
          {payloadArray.map((word: string, index: number) => (
            <tspan key={index} x={0} dy={index > 0 ? "1.2em" : 0}>
              {word}
            </tspan>
          ))}
        </text>
      </g>
    </svg>
  );
};
