import { GrayScaleLogo, InactiveChartContainer } from "./styles";

import { Button } from "@mui/material";
import { Container } from "../styles";
import Heading1 from "../../../common/Heading1";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/images/Staff-Lights_Logo_Large_Neutral.png";

const InactiveChart = () => {
  return (
    <Container>
      <InactiveChartContainer>
        <GrayScaleLogo src={logo} alt="Staff-Lights_Logo" />
        <Heading1>Noch ein kleiner Schritt zur Aktivierung!</Heading1>
        <div>
          Weniger als 10 Personen haben das Onboarding abgeschlossen.
        </div>{" "}
        <td>&nbsp;</td>
        <div>Schicke den ausstehenden Personen eine Erinnerung</div>
        <td>&nbsp;</td>
        <NavLink to="/settings/users" style={{ marginBottom: "7vh" }}>
          <Button variant="confirm">Zum Mitglieder-Management</Button>
        </NavLink>
      </InactiveChartContainer>
    </Container>
  );
};

export default InactiveChart;
