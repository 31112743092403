import { ReactComponent as ContrastIcon } from "../../icons/navigation/contrast.svg";
import { ReactComponent as DetailsIcon } from "../../icons/navigation/details.svg";
import { ReactComponent as DialogIcon } from "../../icons/navigation/dialog.svg";
import { ReactComponent as DiversityIcon } from "../../icons/navigation/diversity.svg";
import { MenuItems } from "../../utils/pages";
import { ReactComponent as PulseIcon } from "../../icons/navigation/pulse.svg";
import React from "react";
import { ReactComponent as ReportIcon } from "../../icons/navigation/report.svg";
import { ReactComponent as SettingsIcon } from "../../icons/navigation/settings.svg";

const NavIcon = ({ active, path }) => {
  const strokeWidth = active ? "1.5px" : "1.2px";
  const width = "26px";

  switch (path) {
    case MenuItems.Contrast.path:
      return <ContrastIcon width={width} strokeWidth={strokeWidth} />;
    case MenuItems.Report.path:
      return <ReportIcon width={width} strokeWidth={strokeWidth} />;
    case MenuItems.Diversity.path:
      return <DiversityIcon width={width} strokeWidth={strokeWidth} />;
    case MenuItems.Areas.path:
      return <DetailsIcon width={width} strokeWidth={strokeWidth} />;
    case MenuItems.Dialog.path:
      return <DialogIcon width={width} strokeWidth={strokeWidth} />;
    case MenuItems.Pulse.path:
      return <PulseIcon width={width} strokeWidth={strokeWidth} />;
    case "settings":
      return <SettingsIcon width={width} strokeWidth={strokeWidth} />;
    default:
      return <ReportIcon width={width} strokeWidth={strokeWidth} />;
  }
};

export default React.memo(NavIcon);
